// import { preset } from 'vue-cli-plugin-vuetify-preset-reply/preset'
import colors from 'vuetify/util/colors'
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
import { createVuetify } from 'vuetify'

const defaultTheme = {
  dark: false,
  colors: {
    primary: colors.deepOrange.darken2,
    secondary: '#FFF100',
    accent: '#8c9eff',
    error: '#b71c1c',
  }
}

export default defineNuxtPlugin((app) => {
  const vuetify = createVuetify({
    icons: {},
    theme: {
      defaultTheme: 'defaultTheme',
      themes: {
        defaultTheme
        // light: {
        //   primary: colors.deepOrange.darken2,
        //   secondary: '#FFF100',
        //   accent: '#8c9eff',
        //   error: '#b71c1c',
        // },
        // dark: {
        //   primary: colors.blue.darken2,
        //   accent: colors.grey.darken3,
        //   secondary: colors.amber.darken3,
        //   info: colors.teal.lighten1,
        //   warning: colors.amber.base,
        //   error: colors.deepOrange.accent4,
        //   success: colors.green.accent3,
        // },
      },
    },
  })

  app.vueApp.use(vuetify)
})
