import { createApi } from 'unsplash-js';

export default defineNuxtPlugin((nuxtApp) => { 
  const runtimeConfig = useRuntimeConfig()
  console.log('access key:', runtimeConfig.public.unsplashAccessKey)
  const unsplash = createApi({
    // apiUrl: runtimeConfig.unsplashApiUrl,
    accessKey: runtimeConfig.public.unsplashAccessKey,
    fetch: fetch,
  });

  return {
    provide: {
      unsplash: {
        getPhoto(photoId: string) {
          return unsplash.photos.get({ photoId }).then((result) => {
            if (result.errors) {
              // handle errors
              console.log('error occurred: ', result.errors[0])
              return null
            } else {
              // handle success
              const photo = result.response
              return photo
            }
          })
        },
        getPhotos(username: string, page: number = 1, perPage: number = 25) {
          return unsplash.users.getPhotos({ username, page, perPage }).then((result) => {
            if (result.errors) {
              // handle errors
              console.log('error occurred: ', result.errors[0])
              return null
            } else {
              // handle success
              const feed = result.response
              // const { total, results } = feed
              return feed 
            }
          })
        },
      }
    }
  }
})