export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":""},{"hid":"keywords","name":"keywords","content":"andika, nugraha, andika nugraha, software, developer, full stack, frontend, backend, web development, ai, machine learning"},{"hid":"language","name":"language","content":"English"},{"hid":"robots","name":"robots","content":"index, follow"},{"hid":"og:title","name":"og:title","content":"Andika Nugraha's Personal Website"},{"hid":"og:image","name":"og:image","content":"https://andikanu.com/favicon.ico"},{"hid":"og:description","name":"og:description"},{"hid":"og:type","name":"og:type","content":"website"},{"hid":"og:locale","name":"og:locale","content":"id_ID"},{"hid":"og:site_name","name":"og:site_name","content":"Andika Nugraha"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.ico"}],"style":[],"script":[],"noscript":[],"titleTemplate":"%s - andikanu","title":"Andika Nugraha's Personal Website"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000