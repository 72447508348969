import type { UseFetchOptions } from "#app"

const BASE_URL = 'https://us-central1-bioskoper-79a20.cloudfunctions.net'
const API_INFO = '/andikanu-youtube-info'
const API_GENERATE_DOWNLOAD = '/andikanu-generate-download'

export default defineNuxtPlugin((nuxtApp) => {
  return {
    provide: {
      api: {
        getBasePromise(uri: string, query: any) {
          uri = encodeURI(uri)
          return new Promise((resolve, reject) => {
            useFetch(uri, {
              method: 'GET',
              query
            }).then(response => {
              resolve(response)
            }).catch((error) => {
              reject(error)
            })
          })
        },
        getYoutubeInfo(query: any) {
          const uri = BASE_URL + API_INFO
          return this.getBasePromise(uri, query)
        },
        generateDownload(query: any) {
          const uri = BASE_URL + API_GENERATE_DOWNLOAD
          return this.getBasePromise(uri, query)
        },
        download(uri: string) {
          // const query = {}
          return new Promise((resolve, reject) => {
            useFetch(uri, {
              method: 'GET',
              responseType: 'arrayBuffer'
            }).then(response => {
              resolve(response)
            }).catch((error) => {
              reject(error)
            })
          })
        },
      }
    }
    
  }
}) 