import { default as berita_45katolik6wpuKkv3RpMeta } from "D:/Repository/andikanu/pages/berita-katolik.vue?macro=true";
import { default as centipede_45privacy_45policybHaw35F6q8Meta } from "D:/Repository/andikanu/pages/centipede-privacy-policy.vue?macro=true";
import { default as centipedeoPFWolcUmhMeta } from "D:/Repository/andikanu/pages/centipede.vue?macro=true";
import { default as congklak_45reimagine_45privacy_45policyuY4Q9V4t3zMeta } from "D:/Repository/andikanu/pages/congklak-reimagine-privacy-policy.vue?macro=true";
import { default as congklak_45reimagine5SKDG5FRy0Meta } from "D:/Repository/andikanu/pages/congklak-reimagine.vue?macro=true";
import { default as contactEimTyXm7mAMeta } from "D:/Repository/andikanu/pages/contact.vue?macro=true";
import { default as gallerytRD0wYuhORMeta } from "D:/Repository/andikanu/pages/gallery.vue?macro=true";
import { default as indexR8OItCXeA5Meta } from "D:/Repository/andikanu/pages/index.vue?macro=true";
import { default as inspire5jXIb4PbN3Meta } from "D:/Repository/andikanu/pages/inspire.vue?macro=true";
import { default as playgroundbACHvPQsBxMeta } from "D:/Repository/andikanu/pages/playground.vue?macro=true";
import { default as projectADKt2YkfP1Meta } from "D:/Repository/andikanu/pages/project.vue?macro=true";
import { default as yt_45download22LN2Gbh2WMeta } from "D:/Repository/andikanu/pages/yt-download.vue?macro=true";
export default [
  {
    name: "berita-katolik",
    path: "/berita-katolik",
    component: () => import("D:/Repository/andikanu/pages/berita-katolik.vue").then(m => m.default || m)
  },
  {
    name: "centipede-privacy-policy",
    path: "/centipede-privacy-policy",
    component: () => import("D:/Repository/andikanu/pages/centipede-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "centipede",
    path: "/centipede",
    component: () => import("D:/Repository/andikanu/pages/centipede.vue").then(m => m.default || m)
  },
  {
    name: "congklak-reimagine-privacy-policy",
    path: "/congklak-reimagine-privacy-policy",
    component: () => import("D:/Repository/andikanu/pages/congklak-reimagine-privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "congklak-reimagine",
    path: "/congklak-reimagine",
    component: () => import("D:/Repository/andikanu/pages/congklak-reimagine.vue").then(m => m.default || m)
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("D:/Repository/andikanu/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "gallery",
    path: "/gallery",
    component: () => import("D:/Repository/andikanu/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("D:/Repository/andikanu/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "inspire",
    path: "/inspire",
    component: () => import("D:/Repository/andikanu/pages/inspire.vue").then(m => m.default || m)
  },
  {
    name: "playground",
    path: "/playground",
    component: () => import("D:/Repository/andikanu/pages/playground.vue").then(m => m.default || m)
  },
  {
    name: "project",
    path: "/project",
    component: () => import("D:/Repository/andikanu/pages/project.vue").then(m => m.default || m)
  },
  {
    name: "yt-download",
    path: "/yt-download",
    component: () => import("D:/Repository/andikanu/pages/yt-download.vue").then(m => m.default || m)
  }
]