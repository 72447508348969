import revive_payload_client_4sVQNw7RlN from "D:/Repository/andikanu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "D:/Repository/andikanu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "D:/Repository/andikanu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "D:/Repository/andikanu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "D:/Repository/andikanu/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "D:/Repository/andikanu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "D:/Repository/andikanu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "D:/Repository/andikanu/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "D:/Repository/andikanu/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "D:/Repository/andikanu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_UYiXMU8ZyN from "D:/Repository/andikanu/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import Vue3Lottie_client_bMZiOS6AB0 from "D:/Repository/andikanu/plugins/Vue3Lottie.client.ts";
import api_GFfDXud5Cr from "D:/Repository/andikanu/plugins/api.ts";
import constants_2m2NibMVoo from "D:/Repository/andikanu/plugins/constants.ts";
import unsplash_QvhLkKfy5n from "D:/Repository/andikanu/plugins/unsplash.ts";
import vuetify_7h9QAQEssH from "D:/Repository/andikanu/plugins/vuetify.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_UYiXMU8ZyN,
  Vue3Lottie_client_bMZiOS6AB0,
  api_GFfDXud5Cr,
  constants_2m2NibMVoo,
  unsplash_QvhLkKfy5n,
  vuetify_7h9QAQEssH
]